import React, { useState, useEffect, useContext, useCallback } from 'react'
import queryString from 'query-string'
import { navigate } from 'gatsby'
import { context } from '../../store'
import callAPI from '../../lib/callAPI'

import { AccountLayout, AccountSubMenu, AccountAddressList, AccountAddressForm } from '../../components'

const AccountAddressesTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext
  const { store } = useContext(context)

  const fetchAddress = useCallback(
    (id_address) => {
      return callAPI(store, {
        href: store.app.endpoints.my_address.href.replace('{id_address}', id_address),
        method: store.app.endpoints.my_address.method
      })
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  useEffect(() => {
    const initialize = async () => {
      if (typeof window !== 'undefined') {
        const qs = queryString.parse(window.location.search)
        if (qs && qs.id && +qs.id > 0) {
          const result = await fetchAddress(qs.id)
          if (result && +result.id_address === +qs.id) {
            setAddress(result)
          }
        }
        if (qs.create) {
          setShowCreate(true)
        }
      }
    }
    initialize()
  }, [fetchAddress])

  const [address, setAddress] = useState()
  const [showCreate, setShowCreate] = useState(false)

  const cancel = (e) => {
    setAddress(null)
    setShowCreate(false)
    navigate('?')
  }

  return (
    <AccountLayout lang={lang} switcher={page}>
  		<div className="wrapper d-flex flex-column flex-md-row">

  			<AccountSubMenu lang={lang} />

  			<div className="content">
          {showCreate ? (
            <AccountAddressForm lang={lang} cancel={cancel} />
          ) : address ? (
            <AccountAddressForm lang={lang} address={address} cancel={cancel} />
          ) : (
            <AccountAddressList lang={lang} setSelected={setAddress} showCreate={() => setShowCreate(true)} />
          )}
  			</div>

  		</div>
    </AccountLayout>
  )
}

export default AccountAddressesTemplate
